<template src="./template.html"></template>

<script>
import { ExcelExport } from 'pikaz-excel-js'
export default {
  name: "BillingPage",
  components: {
    ExcelExport
  },
  data() {
    return {
      customers: [],
      bills: [],
      money_type: [],
      users: [],
      customer_auth: {},
      headers: [
        {
          text: '日期',
          align: 'start',
          value: 'AccountDate',
          width: 150
        },
        {
          text: '客戶',
          align: 'start',
          value: 'CustomerID',
          width: 150
        },
        {
          text: '類型',
          align: 'start',
          value: 'MoneyItemID',
          width: 150
        },
        {
          text: '投放平台',
          align: 'start',
          value: 'Project',
          width: 150
        },
        {
          text: '負責業務',
          align: 'start',
          value: 'Admin',
          width: 150
        },
        {
          text: '說明',
          align: 'start',
          value: 'Remark',
        },
        {
          text: '金額(TWD)',
          align: 'end',
          value: 'Amount',
        },
      ],
      footerprops: {
        'items-per-page-text': '每頁顯示',
      },
      active_customer: -1,
      balance: 0,
      date: ["", ""],
      menu: false,
      modal: false,
      export_sheet: [
        {
          title: "",
          tHeader: ['日期', '客戶名稱', '費用類型', '透放平台', '負責業務', '說明', '金額(TWD)'],
          table: [],
          keys: ['date', 'customer', 'type', 'platform', 'sales', 'description', 'price'],
          sheetName: '',
        }
      ]
    }
  },
  methods: {
    GetMoneyType(type) {
      let type_filter = this.money_type.filter(item => item.MoneyItemID == type)
      return (type_filter.length > 0) ? type_filter[0].Name : ""

    },
    ChangeDate(date) {
      if (new Date(date[0]) > new Date(date[1])) {
        date = date.reverse()
      }
      this.$refs.menu.save(date)
      this.LoadData()
    },
    CustomerFilter(customer) {
      let now_customer = this.customers.filter(item => item.CustomerID == customer)
      return (now_customer.length > 0) ? now_customer[0].Name : ""
    },
    GetAccount(id) {
      let user = this.users.filter(item => item.AdminID == id)
      return user.length > 0 ? user[0].Nickname : ""
    },
    CreateExportTable() {
      this.export_sheet[0].title = `${this.CustomerFilter(this.active_customer)}(${this.date[0]}~${this.date[1]})`
      this.export_sheet[0].sheetName = `${this.CustomerFilter(this.active_customer)}(${this.date[0]}~${this.date[1]})`
      let bill_data = []
      this.active_customer == 'all' ? bill_data = JSON.parse(JSON.stringify(this.bills)) : bill_data = JSON.parse(JSON.stringify(this.bills.filter(item => item.CustomerID == this.active_customer)))
      this.export_sheet[0].table = []
      bill_data.forEach(item => {
        let data = {
          date: item.AccountDate,
          customer: this.CustomerFilter(item.CustomerID),
          type: this.GetMoneyType(item.MoneyItemID),
          platform: "",
          sales: "",
          description: item.Remark,
          price: item.Amount
        }
        item.Project == null ? data.platform = "-" : data.platform = item.Project[0].Type1
        item.Admin.forEach((sales, sales_index) => {
          sales_index == item.Admin.length - 1 ? data.sales += this.GetAccount(sales) : data.sales += this.GetAccount(sales) + "、"
        })
        this.export_sheet[0].table.push(data)
      })
      this.$refs.export_btn.click()
    },
    async LoadData() {
      this.loading = true
      let response = await this.SendData("/admin/money/" + this.active_customer, "post", {
        "StartDate": new Date(this.date[0]).Format("yyyy-MM-dd"),
        "EndDate": new Date(this.date[1]).Format("yyyy-MM-dd")
      })
      this.loading = false
      if (response != "error") {
        console.log(response)

        let Data = response
        Data.forEach(item => {
          Object.keys(this.customer_auth).forEach(customer => {
            item.CustomerID == customer ? item.Admin = this.customer_auth[customer] : ""
          })
        });

        this.bills = Data
        this.bills = this.bills.sort((a, b) => {
          let date_a = new Date(a.AccountDate)
          let date_b = new Date(b.AccountDate)
          return date_b - date_a
        })
      }
    },
    async LoadTypeData() {
      this.loading = true
      let response = await this.GetData("/admin/money/type")
      this.loading = false
      if (response != "error") {

        this.money_type = response
      }
    },
    async LoadCustomerData() {
      this.loading = true
      let response = await this.GetData("/admin/customer")
      this.loading = false
      if (response != "error") {

        this.customers = []
        this.customers.push({ Name: "全部客戶", CustomerID: "all" })
        this.customers = this.customers.concat(response)
        this.active_customer = this.customers[0].CustomerID
      }
    },
    async LoadBalanceData() {
      this.loading = true
      let response = await this.SendData("/admin/money/balance/" + this.active_customer, "post", {
        "StartDate": new Date(this.date[0]).Format("yyyy-MM-dd"),
        "EndDate": new Date(this.date[1]).Format("yyyy-MM-dd")
      })
      this.loading = false
      if (response != "error") {

        this.balance = response.EndBalance
      }
    },
    async LoadAdminData() {
      this.loading = true
      let response = await this.SendData("/admin/customer/auth/get", "post", { Data: {} })
      this.loading = false
      if (response != "error") {
        this.customer_auth = response
      }
    },
    async LoadAccountData() {
      this.loading = true
      let response = await this.GetData("/admin/admin")
      this.loading = false
      if (response != "error") {

        this.users = response
      }
    },

    project_total_price() {
      let money = 0
      if (this.bills != null) {
        this.bills.forEach(item => {
          if (item.MoneyItemID == 2) {
            money += parseInt(item.Amount)
          }
        });
      }
      return money
    },
    charge_total_price() {
      let money = 0
      if (this.bills != null) {
        this.bills.forEach(item => {
          if (item.MoneyItemID == 1) {
            money += parseInt(item.Amount)
          }
        });
      }
      return money
    },
  },
  async created() {
    let date = new Date().toISOString().substr(0, 7)
    let now_year = parseInt(date.substr(0, 4))
    let now_month = parseInt(date.substr(5, 2))
    this.date[0] = new Date(now_year, now_month - 1, 1).Format("yyyy-MM-dd")
    this.date[1] = new Date(now_year, now_month, 0).Format("yyyy-MM-dd")
    await this.LoadCustomerData()
    await this.LoadAdminData()
    await this.LoadAccountData()
    this.LoadTypeData()
    this.LoadData()
  },
  watch: {
    active_customer() {
      this.LoadData()
      this.active_customer != 'all' ? this.LoadBalanceData() : this.balance = 0
    }
  },
  filters: {
    currency(price) {
      if (price == null) {
        return " $0"
      }
      let new_price = Math.abs(parseInt(price)).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')
      return (parseInt(price) < 0) ? "- $" + new_price : " $" + new_price
    },
  },
  computed: {

    dateRangeText() {
      return this.date.join(' ~ ')
    },
  },
}
</script>