var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-col',{staticClass:"pa-md-10 pa-sm-1 pa-3",attrs:{"id":"Bills"}},[_c('v-row',{staticClass:"align-center"},[_c('v-spacer'),_c('v-col',{staticClass:"pt-10",attrs:{"cols":"3"}},[_c('v-select',{attrs:{"items":_vm.customers,"item-text":"Name","item-value":"CustomerID","label":"案件客戶"},model:{value:(_vm.active_customer),callback:function ($$v) {_vm.active_customer=$$v},expression:"active_customer"}})],1),_c('v-col',{staticClass:"pt-10",attrs:{"cols":"3"}},[_c('v-menu',{ref:"menu",attrs:{"close-on-content-click":false,"return-value":_vm.date,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"auto"},on:{"update:returnValue":function($event){_vm.date=$event},"update:return-value":function($event){_vm.date=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"日期區間","readonly":""},model:{value:(_vm.dateRangeText),callback:function ($$v) {_vm.dateRangeText=$$v},expression:"dateRangeText"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('v-date-picker',{attrs:{"range":"","no-title":"","scrollable":"","locale":"zh-tw"},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.menu = false}}},[_vm._v(" 取消 ")]),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.ChangeDate(_vm.date)}}},[_vm._v(" 確認 ")])],1)],1)],1),_c('v-btn',{ref:"export_btn",attrs:{"color":"primary"},on:{"click":_vm.CreateExportTable}},[_vm._v(" 匯出 ")]),_c('excel-export',{staticClass:"ml-auto d-none align-center",attrs:{"sheet":_vm.export_sheet}},[_c('button',{ref:"export_btn",staticClass:"ml-auto",attrs:{"color":"primary"}},[_vm._v(" 匯出 ")])])],1),_c('v-card',{attrs:{"color":"#fff"}},[_c('v-card-text',[_c('v-row',[_c('v-col',[_c('v-data-table',{staticClass:"elevation-0",attrs:{"items-per-page":99999,"headers":_vm.headers,"hide-default-footer":true,"items":_vm.bills,"sort-by":"calories"},scopedSlots:_vm._u([{key:"body.append",fn:function(){return [_c('tr',[_c('td',{attrs:{"colspan":"5"}}),_c('td',{staticClass:"text-right text-subtitle-2",attrs:{"colspan":"2"}},[_vm._v(" 案件費用："+_vm._s(_vm._f("currency")(_vm.project_total_price()))+"・服務費用："+_vm._s(_vm._f("currency")(_vm.charge_total_price()))+"・期末餘額："+_vm._s(_vm._f("currency")(_vm.balance))+" ")])])]},proxy:true},{key:"item.NewTime",fn:function(ref){
var item = ref.item;
return [_c('p',{staticClass:"blue--text text--darken-3 ma-0"},[_vm._v(_vm._s(item.NewTime.slice(0,10)))])]}},{key:"item.Remark",fn:function(ref){
var item = ref.item;
return [_c('p',{staticClass:"blue--text text--darken-3 ma-0"},[_vm._v(_vm._s(item.Remark))])]}},{key:"item.CustomerID",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.CustomerFilter(item.CustomerID))+" ")]}},{key:"item.MoneyItemID",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.GetMoneyType(item.MoneyItemID))+" ")]}},{key:"item.Admin",fn:function(ref){
var item = ref.item;
return _vm._l((item.Admin),function(user){return _c('v-chip',{key:'user_'+user,staticClass:"mr-1"},[_vm._v(_vm._s(_vm.GetAccount(user))+" ")])})}},{key:"item.Project",fn:function(ref){
var item = ref.item;
return [(item.Project!=null)?[_vm._v(" "+_vm._s(item.Project[0].Type1)+" ")]:[_vm._v(" - ")]]}},{key:"item.Amount",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("currency")(item.Amount))+" ")]}},{key:"no-data",fn:function(){return [_c('p',[_vm._v("目前沒有資料")])]},proxy:true}],null,true)})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }